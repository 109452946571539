<template>
  <div>
    <form @submit.prevent="add" @keydown="form.onKeydown($event)">
      <div class="row">
        <div class="col-md-8">
          <b-card>
            <b-card-body>
              <div class="row align-items-center">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="name">Voucher Name *</label>
                    <Input
                      :class="{
                        'is-invalid border-danger': form.errors.has('name'),
                      }"
                      name="name"
                      type="text"
                      v-model="form.name"
                      id="name"
                      placeholder="Enter Voucher Name"
                    />
                    <has-error :form="form" field="name"></has-error>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="discount_type">Select Discount Type *</label>
                    <Select
                      v-model="form.discount_type"
                      filterable
                      :class="{
                        'is-invalid border-danger':
                          form.errors.has('discount_type'),
                      }"
                      name="discount_type"
                      id="discount_type"
                      placeholder="Select Discount Type"
                    >
                      <Option :value="1">Percentage</Option>
                      <Option :value="2">Fixed Amount</Option>
                    </Select>
                    <has-error :form="form" field="discount_type"></has-error>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="discount">Discount *</label>
                    <InputNumber
                      :min="0"
                      style="width: 100%"
                      :class="{
                        'is-invalid border-danger': form.errors.has('discount'),
                      }"
                      name="discount"
                      v-model="form.discount"
                      id="discount"
                      placeholder="Enter Discount"
                      :clearable="true"
                    ></InputNumber>
                    <has-error :form="form" field="discount"></has-error>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="max_apply">How May Time Can Apply</label>
                    <InputNumber
                      :min="0"
                      style="width: 100%"
                      :class="{
                        'is-invalid border-danger':
                          form.errors.has('max_apply'),
                      }"
                      name="max_apply"
                      v-model="form.max_apply"
                      id="max_apply"
                      placeholder="Enter Max Apply"
                      :clearable="true"
                    ></InputNumber>
                    <has-error :form="form" field="max_apply"></has-error>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="course_wise_max_apply"
                      >How May Course Purchase</label
                    >
                    <InputNumber
                      :min="0"
                      style="width: 100%"
                      :class="{
                        'is-invalid border-danger': form.errors.has(
                          'course_wise_max_apply'
                        ),
                      }"
                      name="course_wise_max_apply"
                      v-model="form.course_wise_max_apply"
                      id="course_wise_max_apply"
                      placeholder="Enter Course Wise Max Apply"
                      :clearable="true"
                    ></InputNumber>
                    <has-error
                      :form="form"
                      field="course_wise_max_apply"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="person_wise_max_apply"
                      >Single Person How May Time Can Apply</label
                    >
                    <InputNumber
                      :min="0"
                      style="width: 100%"
                      :class="{
                        'is-invalid border-danger': form.errors.has(
                          'person_wise_max_apply'
                        ),
                      }"
                      name="person_wise_max_apply"
                      v-model="form.person_wise_max_apply"
                      id="person_wise_max_apply"
                      placeholder="Enter Person Wise Max Apply"
                      :clearable="true"
                    ></InputNumber>
                    <has-error
                      :form="form"
                      field="person_wise_max_apply"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="max_add_course">Discount On Max Course</label>
                    <InputNumber
                      :min="0"
                      style="width: 100%"
                      :class="{
                        'is-invalid border-danger':
                          form.errors.has('max_add_course'),
                      }"
                      name="max_add_course"
                      v-model="form.max_add_course"
                      id="max_add_course"
                      placeholder="Enter Max Add Course"
                      :clearable="true"
                    ></InputNumber>
                    <has-error :form="form" field="max_add_course"></has-error>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="max_add_amount">Discount On Max Amount</label>
                    <InputNumber
                      :min="0"
                      style="width: 100%"
                      :class="{
                        'is-invalid border-danger':
                          form.errors.has('max_add_amount'),
                      }"
                      name="max_add_amount"
                      v-model="form.max_add_amount"
                      id="max_add_amount"
                      placeholder="Enter Max Add Amount"
                      :clearable="true"
                    ></InputNumber>
                    <has-error :form="form" field="max_add_amount"></has-error>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="min_amount">Discount On Minimum Amount</label>
                    <InputNumber
                      :min="0"
                      style="width: 100%"
                      :class="{
                        'is-invalid border-danger':
                          form.errors.has('min_amount'),
                      }"
                      name="min_amount"
                      v-model="form.min_amount"
                      id="min_amount"
                      placeholder="Enter Minimum Amount"
                      :clearable="true"
                    ></InputNumber>
                    <has-error :form="form" field="min_amount"></has-error>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="from_expiry_date">Start Date</label>
                    <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      :class="{
                        'is-invalid border-danger':
                          form.errors.has('from_expiry_date'),
                      }"
                      name="from_expiry_date"
                      v-model="form.from_expiry_date"
                      id="from_expiry_date"
                      placeholder="Select Start Date"
                      :clearable="true"
                      style="width: 100%"
                    ></DatePicker>
                    <has-error
                      :form="form"
                      field="from_expiry_date"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="to_expiry_date">Expiry Date</label>
                    <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      :class="{
                        'is-invalid border-danger':
                          form.errors.has('to_expiry_date'),
                      }"
                      name="to_expiry_date"
                      v-model="form.to_expiry_date"
                      id="to_expiry_date"
                      placeholder="Select Expiry Date"
                      :clearable="true"
                      style="width: 100%"
                    ></DatePicker>
                    <has-error
                      :form="form"
                      field="from_expiry_date"
                    ></has-error>
                  </div>
                </div>
                <div class="col-md-4 d-flex justify-content-between">
                  <b-form-checkbox
                    name="status"
                    style="margin-top: 12px"
                    v-model="form.status"
                    switch
                    inline
                  >
                    Status
                  </b-form-checkbox>
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="form.busy"
                  >
                    CREATE
                  </b-button>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card>
            <b-card-body>
              <p><b>Select Course</b></p>
              <b-form-checkbox-group v-model="form.course_id">
                <b-form-checkbox
                  class="mb-1 d-block"
                  v-for="(course, index) in courses"
                  :key="index"
                  v-if="courses.length"
                  :value="course.id"
                >
                  {{ course.name }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BButton,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { Button, HasError, AlertError } from "vform/src/components/bootstrap5";

export default {
  data() {
    return {
      form: new Form({
        name: null,
        discount_type: null,
        discount: null,
        max_apply: null,
        course_wise_max_apply: null,
        person_wise_max_apply: null,
        max_add_course: null,
        max_add_amount: null,
        min_amount: null,
        product_type: 1,
        from_expiry_date: null,
        to_expiry_date: null,
        course_id: [],
        status: true,
      }),
    };
  },
  mounted() {
    this.$store.dispatch("getActiveCourses");
  },
  methods: {
    add() {
      this.form
        .post("/app/purchase/voucher")
        .then(({ data }) => {
          this.s(data.message);
          this.$router.push("/vouchers");
          this.$Loading.finish();
        })
        .catch((e) => {
          this.$Loading.error();
        });
    },
  },
  components: {
    BCard,
    BCardBody,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    Button,
    HasError,
    AlertError,
  },
  computed: {
    courses() {
      return this.$store.getters.getActiveCourses;
    },
  },
};
</script>

<style></style>
